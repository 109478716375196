<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0">
      <div class="card-title">
        <h3 class="card-label">
          Información de la asignatura
        </h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <v-divider class="my-0"></v-divider>
    <div class="card-body">
      <v-container v-if="Object.keys(subjectData).length == 0">
        <v-row>
          <v-col class="text-center"
            >No se encontró información de la asignatura</v-col
          >
        </v-row>
      </v-container>
      <v-container v-else class="px-4">
        <v-row>
          <v-col class="pt-0">
            <div class="black--text mb-1 flex-column">
              <div class="text-body-2">Asignatura</div>
              <span class="text-h6 font-weight-medium">
                {{ subjectData.name }}
              </span>
            </div>
          </v-col>
          <v-col class="pt-0">
            <div class="black--text mb-1 flex-column">
              <div class="text-body-2">Tipo de asignatura</div>
              <span class="text-h6 font-weight-medium">
                {{
                  `${subjectData.subject_evaluation_scale.subject_type}, ${subjectData.subject_evaluation_scale.subject_evaluation_methodology}`
                }}
              </span>
            </div>
          </v-col>
          <v-col class="pt-0">
            <div class="black--text mb-1 flex-column">
              <div class="text-body-2">Grado</div>
              <span class="text-h6 font-weight-medium">
                {{ subjectData.grade }}
              </span>
            </div>
          </v-col>
          <v-col class="pt-0">
            <div class="black--text mb-1 flex-column">
              <div class="text-body-2">Especialidad</div>
              <span class="text-h6 font-weight-medium">
                {{ subjectData.speciality }}
              </span>
            </div>
          </v-col>
          <v-col class="pt-0">
            <div class="black--text mb-1 flex-column">
              <div class="text-body-2">Nivel académico</div>
              <span class="text-h6 font-weight-medium">
                {{ subjectData.academic_level }}
              </span>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "SubjectInformationCard",
  props: {
    subjectData: { type: Object },
  },
};
</script>
