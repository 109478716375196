import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllSubEvaluations = () => {
  return ApiService.get("sub-evaluations");
};

/**
 * GET request to fetch a subevaluations by evaluation
 * @returns {*}
 */
export const getSubEvaluationsByEvaluation = (id) => {
  return ApiService.get("sub-evaluations/evaluation", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getSubEvaluation = (id) => {
  return ApiService.get("sub-evaluations", id);
};

/**
 * POST request to create a new subevaluation
 * @param payload
 * @returns {*}
 */
export const createSubEvaluation = (payload) => {
  return ApiService.post("sub-evaluations", payload);
};

/**
 * PUT request to update a specific subevaluation
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateSubEvaluation = (id, payload) => {
  return ApiService.post("sub-evaluations/update/" + id, payload);
};

/**
 * DELETE request to delete the specified subevaluation
 * @param id
 * @returns {*}
 */
export const deleteSubEvaluation = (id) => {
  return ApiService.delete(`sub-evaluations/${id}`);
};

/**
 * GET request to change status of the specified subevaluation
 * @param id
 * @returns {*}
 */
export const changeStatusSubEvaluation = (id) => {
  return ApiService.get("sub-evaluations/status", id);
};

/**
 * POST request to create a new subevaluation
 * @param payload
 * @returns {*}
 */
export const uploadSubEvaluationExcelWithScores = (id, payload) => {
  return ApiService.post("sub-evaluations/excel/import/" + id, payload);
};

export default {
  getAllSubEvaluations,
  getSubEvaluationsByEvaluation,
  getSubEvaluation,
  createSubEvaluation,
  updateSubEvaluation,
  deleteSubEvaluation,
  changeStatusSubEvaluation,
  uploadSubEvaluationExcelWithScores,
};
