<template>
  <div>
    <v-btn text class="mb-2" @click="goBack">
      <v-icon left> mdi-arrow-left </v-icon>
      Regresar a perfiles
    </v-btn>

    <!-- begin::Subject Information Card -->
    <SubjectInformationCard :subjectData="subjectData"></SubjectInformationCard>
    <!-- end::Subject Information Card -->

    <EvaluationInformationCard
      ref="evaluationInfomationCard"
      :subjectData="subjectData"
      :subject_teacher_id="s_t"
      @set-activity-type="setActivityType"
      @set-evaluation-weight="setEvaluationWeight"
      @set-start-date="setEvaluationStartDate"
      @set-end-date="setEvaluationEndDate"
    ></EvaluationInformationCard>

    <SubEvaluationsListCard
      ref="subEvaluationsListCard"
      :activityType="activityType"
      :evaluationWeight="evaluationWeight"
      :evaluationDateRange="evaluationDateRange"
    ></SubEvaluationsListCard>

    <v-btn
      color="success"
      block
      :loading="isLoading"
      elevation="2"
      large
      @click="submit"
      >Guardar todo</v-btn
    >

    <v-dialog v-model="showEvaluationConfirmationDialog" width="500" persistent>
      <v-card class="py-5">
        <v-card-title
          class="d-flex flex-column justify-content-center align-items-center"
        >
          <v-icon color="success" x-large> mdi-check-circle </v-icon>
          <span
            class="text-h5 font-weight-bold text-center px-5 my-3"
            style="word-break: keep-all; overflow-wrap: break-word"
          >
            Perfil y actividades registradas con éxito
          </span>
        </v-card-title>

        <v-card-text class="text-body-1 text-center">
          ¿Deseas continuar registrando más perfiles?
        </v-card-text>

        <v-card-actions class="d-flex flex-row justify-content-center align-items-center mt-3" style="gap: 6px;">
          <v-btn
            color="success"
            elevation="0"
            @click="showEvaluationConfirmationDialog = false"
          >
            Sí, continuar
          </v-btn>
          <v-btn
            color="success"
            elevation="0"
            text
            outlined
            @click="goToSubjectEvaluations()"
          >
            No, regresar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {
  GET_CURRENT_PAGE_ACTIONS,
  PURGE_CURRENT_PAGE_ACTIONS,
} from "@/core/services/store/role.module";
import Swal from "sweetalert2";
import { mapGetters } from "vuex";
import subjectRepository from "@/repositories/subjectRepository";
import evaluationRepository from "@/repositories/evaluationRepository";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import SubjectInformationCard from "@/components/elements/evaluation/SubjectInformationCard.vue";
import SubEvaluationsListCard from "@/components/elements/create_evaluation/SubEvaluationsListCard.vue";
import EvaluationInformationCard from "@/components/elements/create_evaluation/EvaluationInformationCard.vue";

export default {
  name: "create_evaluation",
  title: "Crear perfil | GE ITR",
  components: {
    EvaluationInformationCard,
    SubjectInformationCard,
    SubEvaluationsListCard,
  },
  data() {
    return {
      subjectData: {},
      activityType: {},
      evaluationWeight: null,
      evaluationStartDate: new Date().toISOString().slice(0, 10),
      evaluationEndDate: new Date().toISOString().slice(0, 10),
      isLoading: false,
      showEvaluationConfirmationDialog: false,
    };
  },
  props: {
    // s propiedad en donde se guarda el id de la asignatura
    s: {
      type: String,
    },
    // s_t iniciales de subject_teacher
    s_t: {
      type: String,
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Mis asignaturas", route: "teacher_subjects" },
      { title: "Perfiles de la asignatura", route: "subject_evaluations" },
      { title: "Perfil", route: "create_evaluation" },
    ]);
    this.$store.dispatch(GET_CURRENT_PAGE_ACTIONS, "Crear perfil");

    this.getSubject();
  },
  beforeDestroy() {
    this.$store.commit(PURGE_CURRENT_PAGE_ACTIONS);
  },
  methods: {
    reloadPage() {
      this.$router.go();
    },
    goToSubjectEvaluations() {
      this.$router.push({
        path: "subject_evaluations",
        query: {
          subject_teacher: this.s_t,
          subject: this.s,
        },
      });
    },
    getSubject() {
      subjectRepository
        .getSubject(this.s)
        .then(({ data }) => {
          this.subjectData = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    goBack() {
      Swal.fire({
        title: "¿Estas seguro que deseas regresar?",
        text: "Si regresas a la página anterior los datos ingresados del perfil se descartaran",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, regresar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$router.go(-1);
        }
      });
    },

    setActivityType({ activity_type_id, number_sub_evaluations }) {
      this.activityType = {
        id: activity_type_id,
        number_sub_evaluations,
      };
    },

    setEvaluationWeight(weigth) {
      this.evaluationWeight = parseFloat(weigth);
    },

    setEvaluationStartDate(date) {
      this.evaluationStartDate = date;
    },

    setEvaluationEndDate(date) {
      this.evaluationEndDate = date;
    },

    submit() {
      this.isLoading = true;

      // Se valida el formulario de la información del perfil
      this.$refs.evaluationInfomationCard.$v.$touch();

      const isInvalidEvaluationForm =
        this.$refs.evaluationInfomationCard.$v.$invalid;
      const areInvalidSubEvaluationsForm =
        this.$refs.subEvaluationsListCard.validateChildElements();

      if (isInvalidEvaluationForm || areInvalidSubEvaluationsForm) {
        Swal.fire({
          title: "Información incorrecta",
          text: "Asegurate de llenar la información del perfil y sus evaluaciones correctamente",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "¡Entendido!",
        });
        this.isLoading = false;
        return;
      }
      const evaluationObj = {
        ...this.$refs.evaluationInfomationCard.evaluation,
        subject_teacher_id: this.s_t,
        user_id: this.currentUserPersonalInfo.id_user,
        sub_evaluations: [...this.$refs.subEvaluationsListCard.subEvaluations],
      };

      this.create(evaluationObj);
    },

    create(payload) {
      this.isLoading = true;
      evaluationRepository
        .createEvaluation(this.objectToFormData(payload))
        .then(({ data }) => {
          // Retrieving data and hidding modal if operation has been completed
          if (data.status) {
            this.showEvaluationConfirmationDialog = true;
            this.resetForms()
          }
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible guardar el registro 🙁",
          });
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    resetForms() {
      this.$refs.evaluationInfomationCard.resetForm();
      this.$refs.subEvaluationsListCard.resetSubEvaluationsList();
    },
  },
  computed: {
    ...mapGetters(["currentPageActions", "currentUserPersonalInfo"]),
    evaluationDateRange() {
      const range = {
        startDate: this.evaluationStartDate,
        endDate: this.evaluationEndDate,
      };
      return range;
    },
  },
};
</script>
