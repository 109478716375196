import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllEvaluationTypes = () => {
  return ApiService.get("evaluation_types");
};

/**
 * GET request to fetch activity categories by subject type
 * @returns {*}
 */
export const getEvaluationTypesByActivityType = (activityTypeId) => {
  return ApiService.get("evaluation_types/activity_type", activityTypeId);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getEvaluationType = (id) => {
  return ApiService.get("evaluation_types", id);
};

/**
 * POST request to create a new activity category
 * @param payload
 * @returns {*}
 */
export const createEvaluationType = (payload) => {
  return ApiService.post("evaluation_types", payload);
};

/**
 * PUT request to update a specific activity category
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateEvaluationType = (id, payload) => {
  return ApiService.update("evaluation_types", id, payload);
};

/**
 * DELETE request to delete the specified activity category
 * @param id
 * @returns {*}
 */
export const deleteEvaluationType = (id) => {
  return ApiService.delete(`evaluation_types/${id}`);
};

/**
 * GET request to change status of the specified activity category
 * @param id
 * @returns {*}
 */
export const changeStatusEvaluationType = (id) => {
  return ApiService.get("evaluation_types/status", id);
};

export default {
  getAllEvaluationTypes,
  getEvaluationTypesByActivityType,
  getEvaluationType,
  createEvaluationType,
  updateEvaluationType,
  deleteEvaluationType,
  changeStatusEvaluationType,
};
