import ApiService from "@/core/services/api.service";

/**
 * GET request to fetch all data
 * @returns {*}
 */
export const getAllEvaluations = () => {
  return ApiService.get("evaluations");
};

/**
 * GET request to fetch evaluations of specified subject
 * @returns {*}
 */
export const getEvaluationsOfSubject = (id) => {
  return ApiService.get("evaluations/subject_teacher", id);
};

/**
 * GET request to fetch a specific data
 * @param id
 * @returns {*}
 */
export const getEvaluation = (id) => {
  return ApiService.get("evaluations", id);
};

/**
 * POST request to create a new evaluation
 * @param payload
 * @returns {*}
 */
export const createEvaluation = (payload) => {
  return ApiService.post("evaluations", payload);
};

/**
 * PUT request to update a specific evaluation
 * @param id
 * @param payload
 * @returns {*}
 */
export const updateEvaluation = (id, payload) => {
  return ApiService.post("evaluations/update/" + id, payload);
};

/**
 * DELETE request to delete the specified evaluation
 * @param id
 * @returns {*}
 */
export const deleteEvaluation = (id) => {
  return ApiService.delete(`evaluations/${id}`);
};

/**
 * GET request to change status of the specified evaluation
 * @param id
 * @returns {*}
 */
export const changeStatusEvaluation = (id) => {
  return ApiService.get("evaluations/status", id);
};

/**
 * POST request to create a new evaluation
 * @param payload
 * @returns {*}
 */
export const getEvaluationsOfSubjectStageByActivityCategory = (payload) => {
  return ApiService.post(
    "evaluations/academic-load/subject-teacher/activity-category",
    payload
  );
};

export default {
  getAllEvaluations,
  getEvaluation,
  createEvaluation,
  updateEvaluation,
  deleteEvaluation,
  changeStatusEvaluation,
  getEvaluationsOfSubject,
  getEvaluationsOfSubjectStageByActivityCategory,
};
