<template>
  <div class="card card-custom gutter-b">
    <div class="card-header flex-nowrap border-0">
      <div class="card-title">
        <h3 class="card-label">
          Información del perfil
        </h3>
      </div>
      <div class="card-toolbar"></div>
    </div>
    <v-divider class="my-0"></v-divider>
    <div class="card-body">
      <v-container class="text-body-1 mx-2 pt-4">
        <!-- begin:: Alerta que se muestra cuando ya no se pueden agregar más perfiles en una fase o categoría -->
        <v-row v-if="!canAddMoreEvaluations" class="mb-3">
          <v-col>
            <v-alert prominent type="error">
              <v-row align="center">
                <v-col class="grow">
                  El límite de perfiles de la
                  {{ subjectType == 1 ? "fase" : "categoría" }} seleccionada ha
                  sido sobrepasado, solo es posible agregar
                  <strong>
                    {{
                      evaluationsOfSubjectAndStageByActivityCategoryDetail.numMaxOfEvaluations
                    }}
                    perfiles.
                  </strong>
                  Asegurate de haber seleccionado la etapa y la
                  {{ subjectType == 1 ? "fase" : "categoría" }}
                  correcta.
                </v-col>
              </v-row>
            </v-alert>
          </v-col>
        </v-row>
        <!-- end:: Alerta que se muestra cuando ya no se pueden agregar más perfiles en una fase o categoría -->
        <!-- begin:: Form of evaluation information -->
        <v-form>
          <!-- begin:: Row with Stage, Activity Category and Activity Type fields -->
          <v-row>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <div class="black--text mb-1 flex-column">
                <form-group
                  name="etapa"
                  :validator="$v.evaluation.academic_load_id"
                >
                  <!-- Everytime stage is selected data is reseted throught resetEvaluationsOfSubjectAndStageByActivityCategoryDetail method -->
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Etapa"
                    :items="academicLoads"
                    item-text="stage.name"
                    item-value="id"
                    required
                    filled
                    rounded
                    dense
                    background-color="grey lighten-4"
                    color="black"
                    v-model="evaluation.academic_load_id"
                    @change="
                      resetEvaluationsOfSubjectAndStageByActivityCategoryDetail
                    "
                  >
                    <template v-slot:item="data">
                      <v-list-item-content>
                        <v-list-item-title
                          v-html="data.item.stage.name"
                        ></v-list-item-title>
                        <v-list-item-subtitle class="mt-1">
                          <span>
                            Desde
                            <strong class="ml-1">{{
                              data.item.stage.start_date
                            }}</strong>
                            hasta
                            <strong class="ml-1">{{
                              data.item.stage.end_date
                            }}</strong>
                          </span>
                        </v-list-item-subtitle>
                      </v-list-item-content>
                    </template>
                  </v-select>
                </form-group>
              </div>
            </v-col>
            <v-col cols="12" sm="6" md="4" class="py-0">
              <div class="black--text mb-1 flex-column">
                <form-group
                  name="fase"
                  :validator="$v.evaluation.activity_category_id"
                >
                  <v-select
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    :label="
                      `Agrupación de perfil (${
                        subjectType == 1 ? 'fase' : 'categoría'
                      })`
                    "
                    :placeholder="
                      `Agrupación de perfil (${
                        subjectType == 1 ? 'fase' : 'categoría'
                      })`
                    "
                    :items="activityCategories"
                    item-text="name"
                    item-value="id"
                    required
                    filled
                    rounded
                    dense
                    background-color="grey lighten-4"
                    color="black"
                    :disabled="!evaluation.academic_load_id"
                    v-model="evaluation.activity_category_id"
                    @change="
                      getActivityTypesOfActivityCategory();
                      getEvaluationsOfSubjectStageByActivityCategory();
                    "
                  ></v-select>
                </form-group>
              </div>
            </v-col>
            <v-col cols="12" sm="12" md="4" class="py-0">
              <div class="black--text mb-1 flex-column">
                <form-group
                  name="tipo de actividad"
                  :validator="$v.evaluation.activity_type_id"
                >
                  <v-autocomplete
                    :disabled="!evaluation.activity_category_id"
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Tipo de actividad"
                    placeholder="Tipo de actividad"
                    :items="activityTypesOfActivityCategory"
                    item-text="name"
                    item-value="id"
                    required
                    filled
                    rounded
                    dense
                    background-color="grey lighten-4"
                    color="black"
                    v-model="evaluation.activity_category_activity_type_id"
                    @change="setActivityType"
                  ></v-autocomplete>
                </form-group>
              </div>
            </v-col>
          </v-row>
          <!-- end:: Row with Stage, Activity Category and Activity Type fields -->

          <!-- begin:: Row with Evaluation description textarea and weight field -->
          <v-row>
            <v-col cols="12" sm="8" md="8" class="py-0">
              <div class="black--text mb-1 flex-column">
                <form-group
                  name="descripción"
                  :validator="$v.evaluation.description"
                >
                  <v-textarea
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    filled
                    rounded
                    dense
                    background-color="grey lighten-4"
                    color="black"
                    counter
                    label="Descripción general de la situación de aprendizaje del perfil"
                    auto-grow
                    rows="2"
                    v-model="evaluation.description"
                  ></v-textarea>
                </form-group>
              </div>
            </v-col>
            <v-col cols="12" sm="4" md="4" class="py-0">
              <div class="black--text mb-1 flex-column">
                <form-group
                  name="ponderación"
                  :validator="$v.evaluation.percent"
                  :messages="errorMessages"
                >
                  <v-text-field
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Ponderación"
                    placeholder="Ponderación"
                    required
                    filled
                    rounded
                    dense
                    background-color="grey lighten-4"
                    color="black"
                    type="number"
                    min="1"
                    v-model.trim="$v.evaluation.percent.$model"
                    prepend-inner-icon="mdi-percent"
                    @change="setEvaluationWeight"
                    :disabled="!evaluation.activity_category_id"
                  />
                </form-group>
                <div
                  v-if="
                    'availablePercentage' in
                      evaluationsOfSubjectAndStageByActivityCategoryDetail
                  "
                  class="text-body-1 mb-2"
                >
                  Hay
                  <strong>{{
                    `${evaluationsOfSubjectAndStageByActivityCategoryDetail.availablePercentage}%`
                  }}</strong>
                  de ponderación disponible del
                  <strong>
                    {{
                      `${evaluationsOfSubjectAndStageByActivityCategoryDetail.totalPercentage}%`
                    }}
                  </strong>
                  total de la
                  <strong>
                    {{ subjectType == 1 ? "fase" : "categoría" }}
                  </strong>
                  seleccionada.
                </div>
              </div>
            </v-col>
          </v-row>
          <!-- end:: Row with Evaluation description textarea and weight field -->

          <!-- begin:: Row with start and end date pickers -->
          <v-row>
            <v-col cols="12" sm="6" class="py-0">
              <div class="black--text mb-1 flex-column">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-model="startDateMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <form-group
                      name="fecha de inicio"
                      :validator="$v.evaluation.start_date"
                      v-bind="attrs"
                    >
                      <v-text-field
                        v-model="evaluation.start_date"
                        label="Fecha de inicio"
                        placeholder="Fecha de inicio"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        required
                        filled
                        rounded
                        dense
                        background-color="grey lighten-4"
                        color="black"
                        :disabled="!evaluation.academic_load_id"
                        v-bind="attrs"
                        slot-scope="{ attrs }"
                        v-on="on"
                      ></v-text-field>
                    </form-group>
                  </template>
                  <v-date-picker
                    v-model="evaluation.start_date"
                    :min="stageStartDate"
                    :max="evaluation.end_date || stageEndDate"
                    @input="
                      startDateMenu = false;
                      setStartDate();
                    "
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
            <v-col cols="12" sm="6" class="py-0">
              <div class="black--text mb-1 flex-column">
                <v-menu
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  v-model="endDateMenu"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <form-group
                      name="fecha de finalización"
                      :validator="$v.evaluation.end_date"
                      v-bind="attrs"
                    >
                      <v-text-field
                        v-model="evaluation.end_date"
                        label="Fecha de finalización"
                        placeholder="Fecha de finalización"
                        prepend-inner-icon="mdi-calendar"
                        readonly
                        required
                        filled
                        rounded
                        dense
                        background-color="grey lighten-4"
                        color="black"
                        :disabled="!evaluation.start_date"
                        v-bind="attrs"
                        slot-scope="{ attrs }"
                        v-on="on"
                      ></v-text-field>
                    </form-group>
                  </template>
                  <v-date-picker
                    v-model="evaluation.end_date"
                    :min="evaluation.start_date"
                    :max="stageEndDate"
                    @input="
                      endDateMenu = false;
                      setEndDate();
                    "
                  ></v-date-picker>
                </v-menu>
              </div>
            </v-col>
          </v-row>
          <!-- end:: Row with start and end date pickers -->

          <!-- begin:: Row with evaluation instrument and modality -->
          <v-row class="pb-2">
            <v-col cols="12" md="8" class="py-0">
              <div class="black--text mb-1 flex-column">
                <form-group
                  name="instrumento de evaluación"
                  :validator="$v.evaluation.evaluation_instrument_document"
                >
                  <v-file-input
                    slot-scope="{ attrs }"
                    v-bind="attrs"
                    label="Instrumento de evaluación"
                    prepend-icon=""
                    prepend-inner-icon="mdi-file-pdf-box"
                    filled
                    rounded
                    dense
                    background-color="grey lighten-4"
                    color="black"
                    accept="application/pdf"
                    truncate-length="15"
                    hint="El instrumento de evaluación del perfil es obligatorio"
                    persistent-hint
                    v-model="evaluation.evaluation_instrument_document"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip v-if="index < 2" color="primary" label small>{{
                        text
                      }}</v-chip>
                    </template>
                  </v-file-input>
                </form-group>
              </div>
            </v-col>
            <v-col cols="12" md="4" class="py-0">
              <div class="black--text mb-1 flex-column">
                <div class="text-body-2 mb-1">
                  Modalidad
                </div>
                <v-radio-group
                  v-model="evaluation.is_group_activity"
                  class="mt-0"
                  row
                  mandatory
                >
                  <v-radio label="Individual" :value="0"></v-radio>
                  <v-radio label="Grupal" :value="1"></v-radio>
                </v-radio-group>
              </div>
            </v-col>
          </v-row>
          <!-- end:: Row with evaluation instrument and modality -->
        </v-form>
        <!-- end:: Form of evaluation information -->
      </v-container>
    </div>
  </div>
</template>

<script>
import activityCategoryRepository from "@/repositories/activityCategoryRepository";
import activityTypeRepository from "@/repositories/activityTypeRepository";
import academicLoadRepository from "@/repositories/academicLoadRepository";
import evaluationRepository from "@/repositories/evaluationRepository";

import {
  required,
  requiredIf,
  minValue,
  maxValue,
  maxLength,
} from "vuelidate/lib/validators";

export default {
  name: "EvaluationInformationCard",
  data() {
    return {
      evaluation: {
        // If percent key is not setted, calidatión won´t work
        percent: null,
      },
      startDateMenu: false,
      endDateMenu: false,
      activityCategories: [],
      activityTypesOfActivityCategory: [],
      academicLoads: [],
      // In this variable is saved info such as
      // - numOfEvaluations: Cantidad de evaluaciones del tipo de actividad en la etapa seleccionada,
      // - numMaxOfEvaluations: Cantidad máxima de evaluaciones permitidas en la categoria o fase seleccionada(activity_category),
      // - accumulatedPercentage: suma de los porcentajes de las evaluaciones traidas,
      // - availablePercentage: diferencia del porcentaje maximo permitido y el acumulado por las evaluaciones ya creadas,
      // - totalPercentage: porcentaje maximo permitido en el categoria de actividad seleccionado,
      evaluationsOfSubjectAndStageByActivityCategoryDetail: {},
      errorMessages: {
        dontExceedTheMax:
          "La sumatoria de la ponderación junto a la que ya hay acumulada por los otros perfiles creados en esta etapa y categoria no debe exceder la total.",
      },
    };
  },
  props: {
    subjectData: {
      type: Object,
    },
    subject_teacher_id: {
      type: String,
    },
  },
  validations: {
    evaluation: {
      start_date: {
        required,
      },
      end_date: {
        required,
      },
      academic_load_id: {
        required,
      },
      activity_category_id: {
        required,
      },
      activity_category_activity_type_id: {
        required,
      },
      description: {
        required,
        maxLength: maxLength(500),
      },
      percent: {
        required,
        dontExceedTheMax(value) {
          if (
            !(
              "availablePercentage" in
              this.evaluationsOfSubjectAndStageByActivityCategoryDetail
            )
          ) {
            return true;
          }

          return (
            value <=
            this.evaluationsOfSubjectAndStageByActivityCategoryDetail
              .availablePercentage
          );
        },
        minValue: minValue(1),
        maxValue: maxValue(100),
      },
      evaluation_instrument_document: {
        required,
      },
    },
  },
  methods: {
    /**
     * Retrieve evaluations that has been created on the same activity category and academic_load(stage) selected
     */
    getEvaluationsOfSubjectStageByActivityCategory() {
      let payload = {
        subject_teacher_id: parseInt(this.subject_teacher_id),
        academic_load_id: this.evaluation.academic_load_id,
        activity_category_id: this.evaluation.activity_category_id,
      };
      evaluationRepository
        .getEvaluationsOfSubjectStageByActivityCategory(payload)
        .then(({ data }) => {
          let numOfEvaluations = data.length;
          let accumulatedPercentage = data.reduce((prev, curr) => {
            const parsedPercent = parseFloat(curr.percent);
            return (prev += parsedPercent);
          }, 0);
          // Se  obtiene el porcentaje disponible de la categoria seleccionada
          let {
            percentage: totalPercentage,
            number_evaluations: numMaxOfEvaluations,
          } = this.activityCategories.find(
            (item) => item.id == payload.activity_category_id
          );
          // Se calcula el porcentaje disponible de esa categoria
          let availablePercentage = totalPercentage - accumulatedPercentage;
          this.evaluationsOfSubjectAndStageByActivityCategoryDetail = {
            numOfEvaluations,
            numMaxOfEvaluations,
            accumulatedPercentage,
            availablePercentage,
            totalPercentage: parseInt(totalPercentage),
          };
        })
        .catch(() => {
          this.fireToast({
            icon: "error",
            title: "No ha sido posible consultar la información 🙁",
          });
        });
    },

    getAcademicLoadOfSubject(subject_id) {
      academicLoadRepository
        .getAcademicLoadsBySubject(subject_id)
        .then(({ data }) => {
          this.academicLoads = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    getActivityCategoriesOfSubjectTypeAndStageId() {
      const activityCategoryId = this.subjectType;
      const stageId = this.stageId;

      if (typeof stageId !== "number" || typeof activityCategoryId !== "number") {
        this.activityCategories = [];
        return;
      }

      activityCategoryRepository
        .getActivityCategoriesBySubjectTypeAndStageId(activityCategoryId, stageId)
        .then(({ data }) => {
          this.activityCategories = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    /**
     * Retrieve all activity types available on selected activity categroy
     */
    getActivityTypesOfActivityCategory() {
      const activityCategoryId = this.evaluation.activity_category_id;
      activityTypeRepository
        .getActivityTypesByActivityCategory(activityCategoryId)
        .then(({ data }) => {
          this.activityTypesOfActivityCategory = data;
        })
        .catch((err) => {
          console.error(err);
          // Sweetalert that indicates the operation cannot be completed
          this.fireToast({
            icon: "error",
            title: "No ha sido posible cargar los datos desde el servidor",
          });
        });
    },

    /**
     * Through this function activity type is setted on parent variable to be passed to SubEvaluationsListCard component
     */
    setActivityType() {
      const activityType = this.activityTypesOfActivityCategory.find(
        (activityType) =>
          activityType.id == this.evaluation.activity_category_activity_type_id
      );
      this.$emit("set-activity-type", activityType);
    },

    /**
     * Throught this function evaluation weight is setted on parent variable
     */
    setEvaluationWeight() {
      this.$emit("set-evaluation-weight", this.evaluation.percent);
    },

    /**
     * Throught this function evaluation start date is setted on parent variable
     */
    setStartDate() {
      this.$emit("set-start-date", this.evaluation.start_date);
    },

    /**
     * Throught this function evaluation end date is setted on parent variable
     */
    setEndDate() {
      this.$emit("set-end-date", this.evaluation.end_date);
    },

    resetForm() {
      this.$v.$reset();
      this.evaluation = {
        percent: null,
      };
      this.evaluationsOfSubjectAndStageByActivityCategoryDetail = {};
      this.activityTypesOfActivityCategory = [];

      // Parent data
      this.$emit("set-activity-type", {
        activity_type_id: -1,
        number_sub_evaluations: 1,
      });
      this.$emit("set-evaluation-weight", 0);
      this.$emit("set-start-date", new Date().toISOString().slice(0, 10));
      this.$emit("set-end-date", new Date().toISOString().slice(0, 10));
    },

    resetEvaluationsOfSubjectAndStageByActivityCategoryDetail() {
      this.evaluationsOfSubjectAndStageByActivityCategoryDetail = {};
      delete this.evaluation.activity_category_id;
      delete this.evaluation.activity_category_activity_type_id;
      this.activityTypesOfActivityCategory = [];
      // Si ya se habia setteado un valor se envia un objeto con información por defecto
      this.$emit("set-activity-type", {
        activity_type_id: -1,
        number_sub_evaluations: 1,
      });
    },
  },
  computed: {
    canAddMoreEvaluations() {
      if (
        Object.keys(this.evaluationsOfSubjectAndStageByActivityCategoryDetail)
          .length == 0
      ) {
        return true;
      }
      return !(
        this.evaluationsOfSubjectAndStageByActivityCategoryDetail
          .numMaxOfEvaluations ==
        this.evaluationsOfSubjectAndStageByActivityCategoryDetail
          .numOfEvaluations
      );
    },
    subjectType() {
      if ("subject_evaluation_scale" in this.subjectData) {
        return this.subjectData.subject_evaluation_scale.subject_type_id;
      }
      return 0;
    },
    stageStartDate() {
      if (!this.evaluation.academic_load_id) {
        return new Date().toISOString().slice(0, 10);
      }
      const { stage } = this.academicLoads.find(
        (academicLoad) => academicLoad.id == this.evaluation.academic_load_id
      );
      return stage.start;
    },
    stageEndDate() {
      if (!this.evaluation.academic_load_id) {
        return new Date().toISOString().slice(0, 10);
      }
      const { stage } = this.academicLoads.find(
        (academicLoad) => academicLoad.id == this.evaluation.academic_load_id
      );
      return stage.end;
    },
    stageId() {
      if (!this.evaluation.academic_load_id) {
        return null;
      }
      const { stage } = this.academicLoads.find(
        (academicLoad) => academicLoad.id == this.evaluation.academic_load_id
      );
      return stage.id;
    }
  },
  watch: {
    subjectData(newValue) {
      if (newValue) {
        const {
          subject_evaluation_scale: { subject_type_id },
          id: subject_id,
        } = newValue;

        this.getAcademicLoadOfSubject(subject_id);
      }
    },
    stageId(curr) {
      if (curr != null) {
        this.getActivityCategoriesOfSubjectTypeAndStageId();
      }
    }
  },
};
</script>
